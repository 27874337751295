@media screen and (max-width: 428px) {
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navBar {
    justify-content: space-between;
    /* width: 88% !important; */
  }
  .navMid {
    display: none;
  }
  .navBar svg {
    margin-left: 0px;
    width: 90px;
  }
  .navEnd {
    display: none;
  }
  .hamburger {
    display: flex;
    margin-top: 8px;
    margin-right: 20px;
    background-color: white;
  }
  .mobileStartMenu {
    margin-top: 21px;
    color: #0047c2 !important;
    position: relative;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .mobileStartMenu:after {
    position: absolute;
    content: "";
    height: 2px;
    border-radius: 1px;
    bottom: 34px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 70%;
    background: #0047c2;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .mobileStartMenu:hover:after {
    width: 50%;
  }
  .hamburger svg {
    height: 22px;
    width: 14px;
    color: #0047c2;
    margin-top: 20px;
  }
  .navEnd {
    margin-right: 0px;
    width: 80px;
  }
  .navBar {
    width: 100%;
  }
  .hero {
    width: 100%;
    height: auto;
    /* max-width: 380px; */
    max-width: 100%;
    overflow-x: hidden;
  }
  .heroCircle {
    width: 380px;
    height: 380px;
    overflow: hidden;
  }
  .heroContent {
    width: 80%;
    line-break: auto;
    margin-top: 50px;
  }
  .heroContent h1 {
    font-size: 55px;
    line-height: 66px;
  }
  .heroContent p {
    font-size: 16px;
    line-height: 24px;
  }
  .heroBtns {
    width: 100%;
    display: flex;
  }
  .getStarted {
    width: 50%;
  }
  .lightBtn {
    width: 50%;
  }
  .splashImg {
    margin-top: 20px;
    max-height: 340px;
    overflow-x: none;
  }
  .section1 {
    height: auto;
  }
  .coreFeatures {
    width: 90%;
  }
  .partners {
    margin-top: 30px;
  }
  .coreFeatures h3 {
    font-size: 36px;
    line-height: 46px;
  }
  .coreFeaturesCols {
    display: inline-block;
    width: 100%;
  }
  .coreCol {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .coreCol img {
    max-width: 100%;
    margin: 0 auto;
  }
  .coreCol p {
    height: auto;
  }
  .coreCol svg {
    margin-left: 15px;
  }
  .howItWorks {
    margin-top: 30px;
    height: auto;
  }
  .HIWContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .HIWLeft {
    width: 90%;
    height: auto;
    margin-left: 5%;
    order: 2;
  }
  .HIWRight {
    order: 1;
    width: 100%;
    height: auto;
  }
  .HIWRight img {
    width: 90%;
    height: auto;
    margin: 0;
    margin-left: 5%;
  }
  .solutions {
    margin-top: 25px;
    height: auto;
    margin-bottom: 30px;
  }
  .solutionsSliderBlock {
    width: 98%;
    margin: 0 auto;
    height: 280px;
    margin-bottom: 0px;
  }
  .slider {
    max-width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
  .carouselITM {
    max-width: 90%;
    margin: 0 auto;
  }
  .carouselITM img {
    max-width: 100%;
    height: auto;
  }
  .section2 {
    padding-top: 0px;
    margin-top: 0px;
  }
  .pricing {
    padding-top: 0px;
    margin-top: 0px;
  }
  .pricing h3 {
    width: 90%;
    font-size: 32px;
    line-height: 42px;
    margin: 0 auto;
  }
  .pricing h4 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
  }
  .pickAPlan {
    width: 90%;
    margin-left: 5%;
  }
  .planWrapper {
    margin-top: 20px;
  }
  .planWrapper h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .pricingP {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .splitPlans {
    display: inline;
  }
  .splitText {
    margin: 6px;
  }
  .priceQuan {
    margin-left: 12px;
  }
  .planLeft {
    width: 100%;
    height: auto;
    border: none;
  }
  .planRight {
    width: 100%;
    height: auto;
  }
  .planLeftUnderMobile p,
  .planRightUnderMobile p {
    text-align: left;
  }
  .planLeftUnder,
  .planRightUnder {
    display: none;
  }
  .planLeftUnderMobile,
  .planRightUnderMobile {
    display: block;
    width: 100%;
    height: auto;
  }
  .over100 {
    width: 90%;
    margin-left: 5%;
    height: auto;
    display: block;
  }
  .over100offer {
    width: 100%;
    height: auto;
  }
  .over100contact {
    width: 100%;
    height: auto;
    margin-left: 0%;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .over100offer p {
    margin-left: 10%;
  }
  .over100offer h4 {
    margin-left: 10%;
  }
  .trustedByTheBest h3 {
    font-size: 36px;
    line-height: 46px;
    width: 90%;
    margin: 0 auto;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    display: none !important;
  }
  .testimonialSlide p {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    font-size: 20px;
    line-height: 28px;
    line-break: auto;
    word-wrap: normal;
    word-break: keep-all;
  }
  .testimonialSlide h3 {
    font-size: 16px;
    line-height: 18px;
  }
  .testimonialSlide img {
    padding-bottom: 20px;
  }
  .testimonialSlide {
    width: 100%;
    height: 450px;
  }
  .faq {
    margin-top: 25px;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
  .faq h3 {
    font-size: 36px;
    line-height: 46px;
    width: 85%;
    margin: 0 auto;
  }
  .faqs {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    height: 420px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    left: 10px;
    right: 10px;
    font-size: 3vw;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 25px !important;
    color: #0047c2;
  }
  .contactUs {
    display: inline;
    margin-top: 0px;
    width: 100%;
    margin-top: 30px;
  }
  .contactLeft {
    width: 90%;
    margin: 0 auto;
  }
  .contactLeft h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .contactRight {
    width: 90%;
    margin: 0 auto;
    padding-top: 0px;
  }
  .contactForm {
    margin-top: 30px;
  }
  .inputLine {
    width: 100%;
    height: auto;
    display: inline;
  }
  .inputGroup {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .contactFooter {
    display: inline;
    height: auto;
  }
  .contactFooter button {
    margin-top: 10px;
    width: 100%;
  }
  .contactFooter p {
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }
  .didntComeThisFar {
    margin-top: 25px;
    width: 100%;
    height: auto;
  }
  .didntComeThisFar p {
    padding-top: 40px;
  }
  .didntComeThisFar h2 {
    width: 90%;
    margin: 0 auto;
    font-size: 36px;
    line-height: 46px;
  }
  .didntComeThisFar h4 {
    width: 90%;
    margin: 0 auto;
    font-size: 4vw;
    line-height: 24px;
    font-weight: 300;
  }
  .didntComeThisFar button {
    margin-bottom: 20px;
  }
  .footer {
    width: 100%;
    height: auto;
  }
  .footerCols {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    flex-wrap: wrap;
  }
  .footCol:first-child {
    display: none;
  }
  .footCol {
    width: 50%;
  }
  .footCol a {
    font-size: 14px;
    line-height: 27px;
  }
  .footSign {
    width: 100%;
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: -30px;
  }
  .footSign a {
    font-size: 3vw;
  }
  .hideMM {
    display: none;
  }
  .showMM {
    display: block;
    z-index: 900;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    background-color: white;
    position: sticky;
    flex-direction: column;
  }
  .mobileMenu ul {
    list-style: none;
    margin-top: 0px;
    padding: 0;
  }
  .mobileMenu li {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 15px;
  }
  .mobileMenu a {
    color: #29303d;
  }
  .partnerImgs {
    display: none;
  }
  .mobilePartnerImgs {
    display: block;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    height: auto;
  }
  .mobilePartnerImgs button {
    display: none;
  }
  .sliderImageMbl {
    display: flex !important;
    align-items: center;
    width: 100%;
    height: 100px;
  }
  .sliderImageMbl img {
    margin: auto;
  }
  .partnerLogo {
    max-width: 130px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #29303d;
  background-color: #f2f6fc !important;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

:root {
  --main-text-color: #29303d;
}

a {
  color: #0047c2;
}

h1 {
  font-size: 65px;
  line-height: 66px;
  font-weight: 400;
  margin: 0;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
h2 {
  font-size: 36px;
  line-height: 46px;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
h3 {
  font-size: 28px;
  line-height: 36px;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
h4 {
  font-size: 22px;
  line-height: 30px;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}

.cta {
  font-size: 16px;
  line-height: 22px;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}

.dsc1 {
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 300;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
.dsc2 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: var(--main-text-color);
  font-family: "Roboto", sans-serif;
  color: #29303d;
}
.navBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  position: sticky;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.navigation {
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 500;
}
.navBar a {
  padding-left: 15px;
  padding-right: 15px;
  color: #29303d;
  text-decoration: none;
}
.navBar svg {
  margin-top: 20px;
  margin-left: 40px;
}
.navMid {
  display: flex;
  margin-top: 30px;
}
.navEnd {
  display: flex;
  margin-top: 30px;
  margin-right: 40px;
}
.navItem:hover {
  color: #0047c2;
}
.navEnd button {
  background-color: #0047c2;
  color: white;
  height: 54px;
  border-radius: 27px;
  width: 161px;
  margin-top: -15px;
  border: none;
}
.navEnd .navItem {
  margin-right: 20px;
}
.hero {
  height: auto;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.heroCircle {
  width: 900px;
  height: 900px;
  background: #f2f6fc;
  border-radius: 50%;
  position: fixed;
  margin: 0 auto;
  margin-top: 80px;
  z-index: 1;
}
.heroContent {
  z-index: 10;
  text-align: center;
  margin-top: 140px;
  color: #29303d;
}
.heroContent p {
  font-size: 20px;
  line-height: 30px;
  color: #29303d;
  margin-top: 10px;
}
.getStarted {
  height: 54px !important;
  background-color: #0047c2 !important;
  color: white !important;
  border: none !important;
  border-radius: 27px !important;
  width: 161px !important;
  margin-right: 5px !important;
}
.lightBtn {
  height: 54px !important;
  margin-left: 5px !important;
  background-color: white !important;
  color: #29303d !important;
  border: none !important;
  border-radius: 27px !important;
  width: 161px !important;
}
.splashImg {
  margin-top: 60px;
  max-width: 1200px;
  border: none;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 40px rgba(105, 110, 119, 0.12));
}
.splashImgMobile {
  display: none;
}
.partners {
  margin-top: 102px;
  margin-bottom: 40px;
}
.partners p {
  color: #0047c2;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
}
.partners h3 {
  margin: 0;
  margin-top: 16px;
  font-weight: 400;
  font-size: 44px;
  line-height: 56px;
}
.partnerImgs {
  height: 91px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 42px;
}
.partnerStSt {
  height: 44px;
}
.partnerRB {
  height: 35px;
}
.partnerCC {
  height: 42px;
}
.partnerTXL {
  height: 91px;
}
.partnerSS {
  height: 65px;
}
.coreFeatures p {
  color: #0047c2;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
}
.coreFeatures h3 {
  margin: 0;
  margin-top: 16px;
  font-weight: 300;
  font-size: 44px;
  line-height: 56px;
}
.section1 {
  height: auto;
  background-color: #f2f6fc;
}
.coreFeatures {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  padding-top: 100px;
}
.coreFeaturesCols {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}
.coreCol {
  width: 450px;
}
.coreCol img {
  width: 450px;
}
.coreCol svg {
  margin-top: -80px;
  margin-bottom: -40px;
}
.coreCol p {
  height: 16px;
  line-height: 24px;
  color: #29303d;
  margin-top: 9px;
}
.coreCol h4 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
}
.howItWorks {
  margin-top: 210px;
  height: 565px;
  background-color: white;
}
.HIWContent {
  display: flex;
  justify-content: center;
}
.HIWLeft {
  width: 30%;
}
.HIWLeft p {
  color: #0047c2;
  margin-top: 69px;
  font-weight: 400;
  margin-bottom: 0px;
}
.HIWLeft h4 {
  font-weight: 400;
  font-size: 44px;
  line-height: 56px;
  margin-bottom: 50px;
}
.HIWRight {
  width: 40%;
}
.HIWRight img {
  height: 705px;
  width: 705px;
  margin-top: -60px;
  margin-left: 20%;
}
.ant-steps-item-icon {
  background-color: #0047c2 !important;
  border: none !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-vertical
  > .ant-steps-item:not(:last-child)
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 3px;
  color: #0047c2;
  height: 5px;
  background-color: #0047c2;
  /* height: 55px; */
}
.solutions {
  margin-top: 190px;
  text-align: center;
}
.solutions p {
  color: #0047c2;
  margin-top: 69px;
  font-weight: 400;
  margin-bottom: 16px;
}
.solutions h3 {
  font-weight: 400;
  font-size: 44px;
  line-height: 56px;
  margin-bottom: 12px;
}
.solutions h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 40px;
}
.carouselITM {
  width: 450px;
  height: 301px;
}
.slider {
  height: 451px;
  margin-bottom: 129px;
}
.solutionsSliderBlock {
  padding-bottom: 30px;
}
.slider button {
  display: none;
}
.carouselITM img {
  width: 440px;
  height: 255px;
  margin-bottom: 0px;
  border-radius: 15px;
}
.carouselITM p {
  margin-top: 8px;
  color: #29303d;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}
.carouselITM {
  width: 90%;
}
.section2 {
  padding-top: 0px;
  background-color: #f2f6fc;
}
.pricing {
  background-color: white;
  text-align: center;
  margin-top: 40px;
  justify-content: center;
  padding-bottom: 0px;
}
.pricingFirstP {
  padding-top: 20px;
}
.pricing p {
  color: #0047c2;
  margin-bottom: 0px;
}
.pricing h3 {
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 44px;
  line-height: 56px;
}
.pricing h4 {
  margin-top: 12px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}
.pickAPlan {
  border: 1px solid #f2f6fc;
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 40px;
}
.planWrapper {
  width: 90%;
  margin: 0 auto;
}
.splitText {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #29303d !important;
}
.rightText {
  float: right;
  color: #29303d;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.tagStyle {
  display: table-cell;
  height: 30px;
  background-color: #02b04e;
  border-radius: 4px;
}
.quantityBox {
  display: flex;
}
.quantityInput {
  height: 47px;
  width: 87px;
  border: none;
  border-radius: 4px;
  background-color: #f2f6fc;
  margin-left: 15px;
  text-align: center;
}
.totalSelected {
  margin-top: -15px;
  height: 30px;
  display: table;
  border-spacing: 15px;
}
.priceQuan {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.splitPlans {
  display: flex;
  justify-content: space-between;
}
.planLeft {
  width: 50%;
  border-right: 1px solid #f2f6fc;
}
.planRight {
  width: 50%;
}
.splitPlans h3 {
  margin-left: 20px !important;
  text-align: left;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px !important;
}
.splitPlans p {
  margin-left: 20px;
  font-size: 16px;
  line-height: 18px;
  color: #29303d;
  text-align: left;
  margin-bottom: 10px;
}
.splitPlans h1 {
  margin-left: 20px;
  font-size: 36px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
}
.splitPlans h1 span {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.planStartBtn {
  width: 90% !important;
  margin: 0 auto;
  background-color: #0047c2 !important;
  color: white !important;
  border: none !important;
  height: 54px !important;
  border-radius: 27px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}
.splitPlans svg {
  margin-right: 10px;
}
.over100 {
  background-color: #0047c2;
  color: white;
  border-radius: 17px;
  position: relative;
  width: 60%;
  margin: 0 auto;
  height: 166px;
  display: flex;
  margin-top: -30px;
  transform: translateY(50%);
}
.over100 h4 {
  color: white;
  text-align: left;
  padding-top: 25px;
  margin-left: 50px;
  font-size: 28px;
  line-height: 30px;
}
.over100 p {
  color: white;
  text-align: left;
  margin-left: 50px;
}
.over100offer {
  width: 50%;
}
.over100contact {
  width: 30%;
  height: 100%;
  margin-top: 55px;
  margin-left: 40%;
}
.trustedByTheBest {
  margin-top: 220px;
  text-align: center;
}
.trustedByTheBest p {
  color: #0047c2;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 9px;
}
.trustedByTheBest h3 {
  font-size: 44px;
  line-height: 56px;
  font-weight: 300;
}
.testimonialSlider {
  margin-top: 35px;
}
.testimonialSlide {
  height: 500px;
  background-color: #0047c2;
  color: white;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 15px;
}
.testimonialSlide h3 {
  color: #f2f6fc;
  opacity: 0.85;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
}
.testimonialSlide p {
  color: white;
  font-size: 22px;
  line-height: 30px;
  width: 70%;
  margin: 0 auto;
}
.testimonialSlide img {
  height: 60px;
  margin: 0 auto;
}
.ssTestimonial {
  filter: invert(100%);
}
.ltTestimonial {
  border-radius: 10px;
}
.ant-carousel .slick-next {
  content: ">" !important;
  display: block !important;
  font-size: 25px !important;
  color: #0047c2 !important;
  background-color: white !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: -20px !important;
  right: 50px !important;
  z-index: 10;
}
.ant-carousel .slick-prev {
  content: "<" !important;
  font-size: 25px !important;
  color: #0047c2 !important;
  background-color: white !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: -20px !important;
  left: 50px !important;
  z-index: 10;
}
.faq {
  margin-top: 120px;
  height: 600px;
  text-align: center;
}
.faq p {
  color: #0047c2;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
}
.faq h3 {
  font-size: 44px;
  line-height: 56px;
  font-weight: 300;
  margin-bottom: 40px;
}
.faqs {
  width: 50%;
  margin: 0 auto;
}
.faqCollapse {
  text-align: left;
  background-color: white !important;
  border-radius: 10px !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  color: #29303d;
  height: 54px;
  align-items: center;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 15px !important;
  left: 20px;
  right: 20px;
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 40px !important;
  color: #0047c2;
}
.faqPanel {
  background-color: white;
  margin-bottom: 15px;
  border-radius: 10px !important;
}
.faqPanel p {
  width: 95%;
  margin: 0 auto;
  color: #29303d;
  font-size: 15px;
  line-height: 22px;
}
.ant-collapse-item-active .ant-collapse-header {
  color: #0047c2 !important;
}
.ant-collapse-header:hover {
  color: #0047c2 !important;
}
.section3 {
  background-color: white;
}
.contactUs {
  width: 80%;
  margin: 0 auto;
  margin-top: 125px;
  display: flex;
  justify-content: space-between;
}
.contactLeft p {
  margin-top: 80px;
  color: #0047c2;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
}
.contactLeft h3 {
  margin-top: 0px;
  font-size: 44px;
  line-height: 56px;
  margin-bottom: 10px;
}
.contactLeft h4 {
  font-size: 18px;
  line-height: 27px;
}
.contactEmails p {
  margin-top: 33px;
  color: #696e77;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}
.contactLeft a {
  text-decoration: none;
  color: #29303d;
  font-size: 16px;
  line-height: 18px;
}
.contactLeft a:hover {
  text-decoration: none;
  color: #29303d;
}
.contactLeft {
  width: 40%;
}
.contactRight {
  padding-top: 80px;
  width: 50%;
}
.inputLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
}
.inputGroup {
  width: 49%;
  height: 80px;
}
.inputGroup p,
.inputArea p {
  color: #29303d;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.contactForm p span {
  color: #0047c2;
}
.inputGroup input {
  height: 54px;
  border: none;
  border-radius: 4px;
  background-color: #f2f6fc;
  padding-left: 20px;
  outline: none;
  box-shadow: none;
}
.inputArea {
  width: 100%;
  height: 176px;
}
.inputArea textarea {
  max-height: 150px !important;
  height: 100% !important;
  border: none;
  border-radius: 4px;
  background-color: #f2f6fc;
  padding: 20px;
  resize: none;
}
.contactFooter button {
  border: none;
  background-color: #0047c2;
  color: white;
  border-radius: 27px;
  height: 54px;
  width: 160px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
}
.contactFooter p {
  font-size: 14px;
  line-height: 16px;
  color: #29303d;
  opacity: 0.7;
  mix-blend-mode: normal;
  margin-top: 22px;
  margin-left: 50px;
}
.contactFooter {
  display: flex;
}
.inputGroup .ant-select {
  width: 100%;
  height: 54px;
}
.ant-select-selector {
  height: 54px !important;
  border: none !important;
  background-color: #f2f6fc !important;
  border-radius: 4px !important;
}
.ant-select-selection-placeholder {
  margin-top: 12px;
  margin-left: 10px;
}
.ant-select-item-option-active {
  background: #f2f6fc !important;
}
.ant-select-selection-item {
  margin-top: 12px;
  margin-left: 10px;
}
.didntComeThisFar {
  margin-top: 125px;
  width: 100%;
  height: 400px;
  background-color: #0047c2;
  text-align: center;
  color: white;
}
.didntComeThisFar p {
  padding-top: 90px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 18px;
  color: white;
}
.didntComeThisFar h2 {
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 44px;
  line-height: 56px;
  color: white;
}
.didntComeThisFar h4 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 27px;
  color: white;
}
.didntComeThisFar button {
  margin-top: 35px;
  height: 54px;
  border: none;
  border-radius: 27px;
  color: #0047c2;
  background-color: white;
  width: 171px;
}
.footer {
  width: 100%;
  height: 230px;
  background-color: white;
}
.footerCols {
  width: 80%;
  display: flex;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 30px;
}
.footCol {
  width: 25%;
}
.footCol a {
  color: #29303d;
}
.footCol a:hover {
  color: #0047c2;
}
.footCol p {
  margin-bottom: 10px;
}
.footSign {
  width: 90%;
  margin: 0 auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f6fc;
  align-items: center;
  margin-bottom: -60px;
}
.footSign a {
  font-size: 14px;
  line-height: 16px;
  color: #696e77;
  font-weight: 300;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}
.footSign p {
  font-size: 14px;
  line-height: 16px;
  color: #696e77;
  font-weight: 300;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}
.footSign a:hover {
  color: #696e77;
}
.offerDiv {
  margin: 0 auto;
  background-color: #f2f6fc;
  font-size: 14px;
  line-height: 16px;
  width: 70%;
  margin-top: 15px;
}
.offerDiv p {
  color: #29303d;
  padding-top: 10px;
  padding-bottom: 10px;
}
.offers p {
  font-size: 16px;
  line-height: 18px;
}
.offerDiv span {
  font-weight: 700;
}
.planLeftUnder {
  width: 50%;
}
.planRightUnder {
  width: 50%;
}
.planLeftUnder p {
  text-align: center;
}
.planRightUnder p {
  text-align: center;
}
.planBenefits {
  padding-bottom: 10px;
  font-weight: 700;
}
.tosModal {
  max-height: 50vh;
  overflow-y: auto;
  width: 97%;
  margin: 0 auto;
}
.tosModal p {
  font-size: 14px;
  line-height: 16px;
  line-break: auto;
  word-wrap: normal;
}
.tosTitle {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.hamburger {
  display: none;
}
.planLeftUnderMobile,
.planRightUnderMobile {
  display: none;
}
.mobileMenu {
  display: none;
}
.mobilePartnerImgs {
  display: none;
}
.backToTop {
  position: sticky;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  bottom: 20px;
  margin-right: 30px;
  margin-left: auto;
  z-index: 99;
  background-color: #0047c2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  animation-duration: 0.5s;
  animation-name: hide;
}
.backToTop svg {
  color: white;
  font-size: 22px;
}
.showToTop {
  opacity: 1;
  animation-duration: 0.5s;
  animation-name: show;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
html {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
}
.hypertekFooter {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 500 !important;
}
.hypertekFooter:hover {
  color: #f20c2e !important;
}
.hyperTerms {
  font-family: "Roboto", sans-serif;
  color: #29303d;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.hyperTerms h1 {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}
.hyperTerms h2 {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
}
.hyperTerms p {
  font-size: 1rem;
  font-weight: 200;
}
.hyperTerms strong {
  font-weight: 600;
}

.partnerLogo {
  width: auto;
  height: 80px;
  max-width: 200px;
  opacity: 0.8;
  object-fit: contain;
}

@media screen and (min-width: 1190px) and (max-width: 1919px) {
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navBar {
    justify-content: space-between;
  }
  .navEnd {
    margin-right: 10px;
  }
  .navEnd .navItem {
    margin-right: 0px;
  }
  .navBar {
    width: 100%;
  }
  .hero {
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow-x: hidden;
  }
  .heroCircle {
    width: 600px;
    height: 600px;
    overflow: hidden;
  }
  .heroContent {
    width: 80%;
    line-break: auto;
    margin-top: 50px;
  }
  .heroContent h1 {
    font-size: 55px;
    line-height: 66px;
  }
  .heroContent p {
    font-size: 16px;
    line-height: 24px;
  }
  .heroBtns {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .getStarted {
    width: 50%;
  }
  .lightBtn {
    width: 50%;
  }
  .splashImg {
    margin-top: 20px;
    max-width: 90%;
    overflow-x: none;
  }
  .section1 {
    height: auto;
  }
  .coreFeatures {
    width: 90%;
  }
  .partners {
    margin-top: 30px;
  }
  .coreFeatures h3 {
    font-size: 36px;
    line-height: 46px;
  }
  .coreFeaturesCols {
    display: flex;
    width: 100%;
  }
  .coreCol {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .coreCol img {
    max-width: 90%;
    margin: 0 auto;
  }
  .coreCol p {
    height: auto;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .coreCol svg {
    transform: translateX(5%);
  }
  .howItWorks {
    margin-top: 30px;
    height: auto;
  }
  .HIWRight img {
    transform: translateY(6%);
    height: 600px;
    width: 600px;
    margin-left: 10%;
  }
  .HIWLeft h4 {
    margin-bottom: 30px;
  }
  .HIWLeft .getStarted {
    margin-top: 10px !important;
  }
  .solutions {
    margin-top: 25px;
    height: auto;
    margin-bottom: 30px;
  }
  .solutionsSliderBlock {
    width: 98%;
    margin: 0 auto;
    height: 280px;
    margin-bottom: 0px;
  }
  .slider {
    max-width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
  .carouselITM {
    max-width: 90%;
    margin: 0 auto;
  }
  .carouselITM img {
    max-width: 100%;
    height: auto;
  }
  .section2 {
    padding-top: 0px;
    margin-top: 0px;
  }
  .pricing {
    padding-top: 0px;
    margin-top: 0px;
  }
  .pricing h3 {
    width: 90%;
    font-size: 32px;
    line-height: 42px;
    margin: 0 auto;
  }
  .pricing h4 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
  }
  .pickAPlan {
    width: 90%;
    margin-left: 5%;
  }
  .planWrapper {
    margin-top: 20px;
  }
  .planWrapper h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .pricingP {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .planLeftUnder p,
  .planRightUnder p {
    text-align: left;
    margin-left: 30px;
  }
  .planStartBtn {
    width: 90% !important;
  }
  .over100 {
    width: 90%;
    margin-left: 5%;
    height: 150px;
    display: flex;
    align-items: center;
  }
  .over100offer {
    width: 70%;
    height: auto;
  }
  .over100contact {
    width: 30%;
    height: auto;
    margin-left: 0%;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .over100offer p {
    margin-left: 10%;
  }
  .over100offer h4 {
    margin-left: 10%;
    padding-top: 0px;
    font-weight: 700;
  }
  .trustedByTheBest {
    margin-top: 120px;
  }
  .trustedByTheBest h3 {
    font-size: 36px;
    line-height: 46px;
    width: 90%;
    margin: 0 auto;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    display: none !important;
  }
  .testimonialSlide p {
    width: 60%;
    margin: 0 auto;
    padding-top: 60px;
    font-size: 20px;
    line-height: 28px;
    line-break: auto;
    word-wrap: normal;
    word-break: keep-all;
  }
  .testimonialSlide h3 {
    font-size: 16px;
    line-height: 18px;
  }
  .testimonialSlide img {
    padding-bottom: 20px;
  }
  .testimonialSlide {
    width: 100%;
    height: 350px;
  }
  .faq {
    margin-top: 25px;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
  .faq h3 {
    font-size: 36px;
    line-height: 46px;
    width: 85%;
    margin: 0 auto;
  }
  .faqs {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    height: 420px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    left: 10px;
    right: 10px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 25px !important;
    color: #0047c2;
  }
  .contactUs {
    display: flex;
    margin-top: 30px;
    width: 90%;
    margin-top: 30px;
  }
  .contactLeft {
    width: 90%;
    margin: 0 auto;
  }
  .contactLeft h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .contactRight {
    width: 100%;
    margin: 0 auto;
    padding-top: 0px;
  }
  .contactForm {
    margin-top: 30px;
  }
  .contactFooter {
    display: flex;
    height: auto;
    width: 100%;
  }
  .contactFooter button {
    margin-top: 10px;
    width: 30%;
  }
  .didntComeThisFar {
    margin-top: 25px;
    width: 100%;
    height: auto;
  }
  .didntComeThisFar p {
    padding-top: 40px;
  }
  .didntComeThisFar h2 {
    width: 90%;
    margin: 0 auto;
    font-size: 36px;
    line-height: 46px;
  }
  .didntComeThisFar h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }
  .didntComeThisFar button {
    margin-bottom: 20px;
  }
  .footerCols {
    padding-top: 50px;
  }
  .hideMM {
    display: none;
  }
  .showMM {
    display: block;
    z-index: 900;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    background-color: white;
    position: sticky;
    flex-direction: column;
  }
  .mobileMenu ul {
    list-style: none;
    margin-top: 0px;
    padding: 0;
  }
  .mobileMenu li {
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    padding: 15px;
  }
  .mobileMenu a {
    color: #29303d;
  }
  .partnerImgs {
    display: none;
  }
  .mobilePartnerImgs {
    display: block;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    height: auto;
  }
  .mobilePartnerImgs button {
    display: none;
  }
  .sliderImageMbl {
    display: flex !important;
    align-items: center;
    width: 100%;
    height: 100px;
  }
  .sliderImageMbl img {
    margin: auto;
  }
}

@media screen and (min-width: 1920px) {
  .navigation {
    width: 100%;
  }
  .navBar {
    width: 1920px;
    margin: 0 auto;
  }
  .hero,
  .section1,
  .section2,
  .section3,
  .footer {
    width: 1920px;
    margin: 0 auto;
  }
}
